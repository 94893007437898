@media only screen and (min-width: 320px) and (max-width: 339px) {
/*** **************  ************** ****
		*** Login ***
**** **************  ************** ***/
	.existingLoginForm > ul > li .forgotpassword{
		width:100%;
	}   
} 
@media only screen and (min-width: 340px) and (max-width: 420px) { 
 
	.mainMenuGrid > ul > li{
		width:50%;
	}  
	.navUserWrapper {
		width: 280px; 
	}
	.userProfile {  
		padding: 15px 15px 0px; 
	}
	.userProfile > h4{
		font-size:24px;
	}
	.userProfile .userDetails .userProfileImg {
		width: 44px;
		height: 44px; 
		margin-right: 5px;
	}
	.userProfile .userDetails .userProfileImg > img {
		max-width: 44px;
	}
	.userProfile .userDetails .userNameRole {
		width: 188px;
	}
	.userProfile .userDetails .userNameRole .userName {
		font-size: 18px;
	}
	.userProfile .userDetails .userNameRole .userRole {
		font-size: 14px;
	}
	.userProfile ul.userDetailsList { 
		padding: 15px 0px 0px 0px;
	}
	.userProfile ul.userDetailsList > li { 
		font-size: 16px;
	} 
	.bg-Welbilt .navbarRight > li > a.navBack{
		display:none !important;
	} 
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
/*** **************  ************** ****
		*** Login ***
**** **************  ************** ***/
	.loginButton > ul > li .loginIWrapper{
		width:100%;
	}
	.loginButton > ul > li{
		padding:5px 0px;
	}
	.loginButton > ul > li:nth-child(1),
	.loginButton > ul > li:nth-child(3){
		width:15px;
	}
	.loginButton > ul > li:nth-child(2){
		width:auto;
	} 
	.SignInSuccessModal .singINConfirmation{
		padding:0px 0px 10px;
	}
	.modal-dialog.modal-md{ 
		width:100%;
		padding: 0px 15px;
		margin: 0px;
	} 
	.modal-body.lgoinModalBody{ 
		padding: 0px 0px 15px;
	}  
	.loginModalHeader{
		padding:15px;
	}  
	.existingLoginForm > ul > li .forgotpassword, 
	.existingLoginForm > ul > li .customCheckbox label{
		font-size:14px;
	} 
	.navbarLeft > li:first-child {
		border-right: 0px;
		width: 160px; 
		min-width: 120px; 
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.navbarLeft > li .navStoreIcon { 
		padding: 0px 5px 0px 0px;
	} 
	.selectedNavbarLeft > li{
		width:170px;    
		padding: 10px 0px 9px 5px;
	} 
	.selectedNavbarLeft > li .reportNav,
	.selectedNavbarLeft > li .haccpNav,
	.selectedNavbarLeft > li .menuNav,
	.selectedNavbarLeft > li .UnitRegistrationNav,
	.selectedNavbarLeft > li .UserManagementNav,
	.selectedNavbarLeft > li .softwareNav{
		width:100%;  
		float:left;		
		padding: 1px 5px 1px 45px; 
		font-size: 14px; 
	}  
	.addUserForm,
	.userMgmtHeader,
	.userMgmtHeader > ul,
	.unitRegForm,
	.reportHeader,
	.reportHeader > ul,
	.reportHeader > ul > li
	.unitRegHeader,
	.unitRegHeader > ul,
	.unitRegHeader > ul > li
	.reportHeader,
	.reportHeader > ul,
	.reportHeader > ul > li	{
		width:100%;  
		float:left;
	}
	.userMgmtHeader > ul > li:nth-child(1),
	.userMgmtHeader > ul > li:nth-child(2)	{
		width:50%;  
		float:left;
		text-align:center;
		padding: 8px 15px;
	}
	.reportHeader > ul > li:nth-child(1),
	.unitRegHeader > ul > li:nth-child(1) { 
		text-align:center;
		width:100%;  
		float:left;
		padding: 8px 15px;
	}
	.reportHeader > ul > li:nth-child(2),
	.unitRegHeader > ul > li:nth-child(2),
	.userMgmtHeader > ul > li:nth-child(3)	{
		border-top: 1px solid #7e8690;
		text-align:right;
		width:100%;  
		float:left;
		padding: 8px 15px;
	}
	.reportHeader > ul > li:nth-child(2),
	.unitRegHeader > ul > li:nth-child(2) {
		border-top: 1px solid #7e8690;
		text-align:right;
	}
	.addUnitHeader > ul > li:nth-child(2) { 
		font-size: 18px;  
	}
} 

@media only screen and (min-width: 320px) and (max-width: 568px) {     
	.navbarRight > li.nav-item.userManualDownload{
		display:none;
	}  
	.userManagementWrapper .exitingUserRolesSelection .exitingRolesSelect{
		width:100%;
	}
	.reportHeader > ul > li a{
		padding: 8px 10px 9px;
	}  
	.navbarLeft > li .navStoreIcon > img{
		width:22px;
		height:22px;
	} 
	.navbar .welbiltLogo{
		margin:0px auto;
	}   
	.updateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSB, 
	.unitRegListTable > ul.tBody > li .unitsRSM,
	.unitRegListTable > ul.tBody > li .unitsASM,
	.unitRegListTable > ul.tBody > li .unitsListSM, 
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.SWlistUnitsTable > ul.tBody > li .unitsListSML{
		display:block;
		font-size:12px;
	}   
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.SWlistUnitsTable > ul.tBody > li .unitsListAN,
	.unitRegListTable > ul.tBody > li .unitsListAN{
		display:block; 
	}   
	.updateStatusTable > ul > li:nth-child(2),
	.unitRegListTable > ul > li:nth-child(2) {
		padding-left: 10px;
	}  
	.updateStatusTable > ul > li:nth-child(5) {
		width: 130px;
		padding:10px 0px;
		text-align: left; 
	}  
	.SWlistUnitsTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(3) {
		padding-left:0px;
	}           
	.panel-body.updateStatusExpTable > ul > li:nth-child(1),  
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(6),
	.updateStatusTable > ul > li:nth-child(8), 
	.unitRegListTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4),
	.unitRegListTable > ul > li:nth-child(5), 
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(8), 
	.SWlistUnitsTable > ul > li:nth-child(1),
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5), 
	.SWlistUnitsTable > ul > li:nth-child(7),
	.SWlistUnitsTable > ul > li:nth-child(8),
	.SWlistUnitsTable > ul > li:nth-child(9){ 
		display:none;
	}     
	.SWlistUnitsTable > ul > li:nth-child(6),
	.unitRegListTable > ul > li:nth-child(6) { 
		min-width:130px;
		width:auto;
		padding:7px 5px;
		text-align:left;
	}   
	
	.newReportWrapperL,
	.newReportWrapperR{ 
		width:100%;
		padding-right: 0px; 
		min-height: auto;
	}
	.reportTypeList{
		padding: 10px 0px; 
	} 
	.newReportWrapperR{
		padding: 10px 0px 10px 0px; 
		border-left:none;		
	}
	.newReportWrapper{
		padding: 10px 10px;
	}
	.newReportForm > h6,
	.reportTypeList > h6{
		padding: 0px 0px 10px;
	}
	.newReportWrapperR .maxDuration { 
		font-size: 12px;
	}
	.reportTypeList > ul > li .ErrorReportIcon{
		width: 100%;
		float: none;
		clear: both;
		padding: 0px 0px 0px 0px;
	}

	.newReportForm > ul > li .input-group,
	.newReportForm > ul > li > label{
		clear: none;
	}  
	.addUserForm > ul > li .form-control{
		font-size:18px;
	}
	.addUserForm > ul > li.cityName,
	.addUserForm > ul > li.streetName{
		width:100%;
		margin-right:0px;
	} 
	.addUserForm > ul > li.streetNumber{
		width:50%; 
	}
	.addUserForm > ul > li.zipcode{
		width:48%; 
		margin:0px;
		float:right;
	}  
	.assignedUnitGrpsTable > ul > li,
	.myInformationTable > ul > li h4,
	.myInformationTable > ul > li h5,   
	.updateStatusExpTable > ul > li,
	.updateStatusExpTable > ul > li h4 {  
		font-size:14px; 
	}   
	.updateStatusExpTable > ul > li h5 {  
		font-size:12px; 
	}  
} 
@media only screen and (min-width: 480px) and (max-width: 568px) { 
	.modal-dialog.modal-md{ 
		margin: 0px;
	} 
 
	
} 
@media only screen and (min-width: 568px) and (max-width: 765px) {  
	.updateStatusTable > ul.tBody > li .updateStatusSM,
	.updateStatusTable > ul.tBody > li .updateStatusSB{
		display:block;
		font-size:12px;
	}  
	.updateStatusTable > ul > li:nth-child(2){
		padding-left: 10px;
	}    
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(6),
	.updateStatusTable > ul > li:nth-child(8){
		display:none;
	} 
	.newReportWrapper{
		padding: 10px 10px;
	} 
	.reportTypeList{
		padding: 10px 0px; 
	} 
	.newReportWrapperR{
		padding: 10px 10px; 
	} 
	.newReportForm > h6,
	.reportTypeList > h6{
		padding: 0px 0px 10px;
	}  
} 
@media only screen and (min-width: 568px) and (max-width: 834px) { 
	.navbarLeft > li .navStoreIcon > img{
		width:22px;
		height:22px;
	}
/*** **************  ************** ****
	*** Dashboard ***
**** **************  ************** ***/      
	.mainMenuGrid > ul > li{
		width:33.33%;
	}    
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.unitRegListTable > ul.tBody > li .unitsListSM{
		display:block;
		font-size:12px;
	}   
	.unitRegListTable > ul.tBody > li .unitsListAN{
		display:block; 
	}   
	.unitRegListTable > ul > li:nth-child(2) {
		padding-left: 10px;
	} 
	.SWlistUnitsTable > ul > li:nth-child(2){
		padding:7px 0px;
	}  
	.SWlistUnitsTable > ul > li:nth-child(3){
		padding-left:0px;
	}  
	.SWlistUnitsTable > ul.tBody > li:nth-child(2) > img {
		width: 36px;
	}       
	.panel-body.updateStatusExpTable > ul > li:nth-child(1),   
	.unitRegListTable > ul > li:nth-child(1),
	.unitRegListTable > ul > li:nth-child(3),
	.unitRegListTable > ul > li:nth-child(4), 
	.unitRegListTable > ul > li:nth-child(5),  
	.SWlistUnitsTable > ul > li:nth-child(4),
	.SWlistUnitsTable > ul > li:nth-child(5),   
	.SWlistUnitsTable > ul > li:nth-child(9){ 
		display:none;
	}   
	.SWlistUnitsTable > ul > li:nth-child(6),
	.SWlistUnitsTable > ul > li:nth-child(7) { 
		min-width:120px;
		width:auto;
		padding:7px 5px;
	}     
	.unitRegListTable > ul > li:nth-child(7),
	.unitRegListTable > ul > li:nth-child(8) { 
		width:100px;
		padding:7px 0px;
	} 
	.unitRegListTable > ul > li:nth-child(5) {  
		padding:7px 0px;
	} 
} 
@media only screen and (min-width: 320px) and (max-width: 834px) {  
	.addUserForm>ul>li .form-control.countryCode{
		width:30%;
	}
	.addUserForm>ul>li .form-control.phoneNumber {
		width: 68%;
	}   
	.softwareUpdateHeader > ul > li a { 
		margin-right:10px;
		padding:8px 15px 9px; 
	} 
	.userProfileHeader > ul > li a { 
		margin-right:0px;
		padding:8px 15px 9px; 
		font-size:16px;
	}  
	.softwareUpdateHeader > ul > li a:last-child { 
		margin-right:0px;  
	}  
	#wrapper .navbar .welbiltRLogo {
		display:none;
	}
	#wrapper .navbar .sideNavBar { 
		display:block;
	}
	#wrapper {
		padding-left: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive #page-content-wrapper{
		margin-left: 230px; 
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	} 
	
	#wrapper.navActive .navbar .sideNavBar {
		background: url(../images/icons/sideNavBarE.png) no-repeat center center;
		background-size: auto 36px; 
	} 
	#wrapper #page-content-wrapper{
		margin-left: 0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	} 
	#wrapper #sidebar-wrapper{
		width: 0px; 
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	} 
	#wrapper.navActive .bg-Welbilt.fixed-top{
		left:230px;
		width: 100%;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive .sideNavToggleTransparent{
		display:block; 
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper .bg-Welbilt.fixed-top{
		left:0px;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	#wrapper.navActive #sidebar-wrapper { 
		width: 230px;  
		z-index: 9;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	} 
	#wrapper.navActive { 
		overflow:hidden; 
	} 
	#wrapper.navActive .sidebarNav.bottomNav{ 
		position: fixed;
	}
	#wrapper .navbar .welbiltRLogo {
		background: url("../images/WBTKitchenConnectWhite.png") no-repeat center center;
		background-size: auto 40px;
		width: 230px; 
		border-right: 2px solid #000;
	} 
	.sidebarNav > li.brandName .homeNav,
	.sidebarNav > li .dashboardNav,
	.sidebarNav > li .reportNav,
	.sidebarNav > li .menuNav{
		width: 230px;
		height: 64px;
		display: block; 
		text-indent:0px;
		position:relative;
		line-height:64px;
		padding:0px 10px 0px 74px;
		background: #000000;
		border-bottom:1px solid #1f2124;
		border-right: 2px solid #000;
		font-size:14px;
		color:#afb1b2;
	} 
	.sidebarNav > li.brandName .homeNav{ 
		background:#1f2124; 
	} 
	.sidebarNav > li .menuNav { 
		border-top:1px solid #1f2124; 
		border-bottom:0px; 
	} 
	.sidebarNav > li a.active, 
	.sidebarNav > li a:hover {
		border-bottom:1px solid #4f4f4f;
		text-indent:0px;
		font-size:14px;
		color:#fff;
		background:none;
	}
	.sidebarNav > li .menuNav.active, 
	.sidebarNav > li .menuNav:hover {
		border-top:1px solid #4f4f4f; 
		border-bottom:0px; 
	}
	.sidebarNav > li a.active, 
	.sidebarNav > li a:hover {
		border-bottom:1px solid #4f4f4f;
		text-indent:0px;
		font-size:14px;
		color:#fff;
		background:none;
	}
	.sidebarNav > li a.active:before, 
	.sidebarNav > li a:hover::before { 
		background-color:#4f4f4f;
	} 
	.sidebarNav > li.brandName .homeNav:before,
	.sidebarNav > li .dashboardNav:before,
	.sidebarNav > li .reportNav:before,   
	.sidebarNav > li .menuNav:before{
		position:absolute;
		top:0px;
		left:0px;
		content:" ";
		width: 64px;
		height: 64px;
	} 
	.sidebarNav > li.brandName .homeNav:before { 
		left:15px; 
		width: 265px; 
	} 
	.sidebarNav > li.brandName .homeNav:before {  
		background: #1f2124 url("../images/icons/home.svg") no-repeat left center;
		background-size: auto 36px;
	} 
	.sidebarNav > li .dashboardNav:before { 
		background:#1f2124 url("../images/icons/dashboard.svg") no-repeat center center;
		background-size: 24px auto;
	}
	.sidebarNav > li .reportNav:before { 
		background:#1f2124 url("../images/icons/report.svg") no-repeat center center;
		background-size: 24px auto;
	} 
	.sidebarNav > li .menuNav:before { 
		background:#1f2124  url("../images/icons/menu.svg") no-repeat center center;
		background-size: 24px 24px;
	}  
	.scheduleUpdateWrapperR,
	.scheduleUpdateWrapperL {
		width: 100%;
		padding-right: 0px; 
		min-height: auto;
	} 
	.scheduleUpdateWrapperR{
		padding: 10px 0px;
		border-left: 0;
	} 
	.scheduleUpdateWrapper { 
		padding:15px;
	}
	.scheduleUpdateOption > .customRadioBtn { 
		margin: 10px 0px 10px;
	}
	.scheduleUpdateForm { 
		padding: 0px 0px 5px 0px; 
	}
	.userMgmtRolesTable > ul > li:nth-child(2),
	.userMgmtListTable > ul > li:nth-child(2){ 
		padding-left:10px;
		width: 60px;
	}
	.userMgmtListTable > ul > li:nth-child(3) {
		width:auto; 
		min-width:180px;
	}
	.reportListTable > ul > li:nth-child(5){
		width:80px;
	}
	.reportListTable > ul > li{
		font-size:14px;
	} 	 
	.navbarLRight.timeZoneSNheader,
	.reportListTable > ul > li:nth-child(1),
	.reportListTable > ul > li:nth-child(4), 
	.selectedUnitHeader > ul > li:nth-child(2),
	.userRoleVETable > ul > li:nth-child(1),
	.userMgmtRolesTable > ul > li:nth-child(1),
	.userMgmtListTable > ul > li:nth-child(1), 
	.userMgmtListTable > ul > li:nth-child(4),
	.userMgmtListTable > ul > li:nth-child(5){
		display:none;
	} 
	.userMgmtListTable > ul > li:nth-child(3),
	.userMgmtListTable > ul > li:nth-child(6){
		padding-right:0px;
		padding-left:0px;
	}  
	.userMgmtListTable > ul.tBody > li .userMListRL{
		display:block;
	}
	.selectedUnitHeader > ul > li:nth-child(1) { 
		font-size: 14px; 
	} 
	.selectedUnitHeader > ul > li:nth-child(1) a { 
		margin-right: 15px;
	}
	.updateStatusTable > ul > li { 
		padding: 5px 10px; 
	} 
	.addUserForm { 
		margin: 0px auto; 
	}
	.assigntoBrands > ul > li:nth-child(1) { 
		padding-left: 10px;
	}
	.assigntoBrands > ul > li .customCheckbox {
		margin: 10px 10px 5px 0px; 
		width: auto;
	}
	.userManagementWrapper .exitingUserRolesSelection { 
		padding: 0px 10px 10px; 
	}
	.userRoleVETable > ul > li:nth-child(4), 
	.userRoleVETable > ul > li:nth-child(6) {
		width: 45px; 
	} 
	.chooseUserForm > ul { 
		padding: 0px 0px 0px;
	}
	.userRoleVETable { 
		margin: 15px 0px 0px;
	}
	.chooseUserForm > ul > li .userNameDP > .userDPImg { 
		margin: 0px 10px 0px 10px;
	}
	.chooseUserForm > ul > li .userNameDP { 
		line-height:48px;
		font-size: 18px; 
	}
	.chooseUserForm > ul > li { 
		padding: 0px 0px 0px; 
	}
} 
@media only screen and (min-width: 320px) and (max-width: 1023px) { 
/*** **************  ************** ****
		*** Login ***
**** **************  ************** ***/
	body.loginBg {
		background: #333333 url(../images/Keyvisual.png) no-repeat center center;
		background-size: 100% auto;
	}  
	.updateStatusTable > ul > li:nth-child(2){
		padding-left: 10px;
	}  
	.availableUpdatesTable > ul > li:nth-child(3),
	.availableUpdatesTable > ul > li:nth-child(4),
	.availableUpdatesTable > ul > li:nth-child(5), 
	.updateStatusTable > ul > li:nth-child(1),
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4){
		display:none;
	}
	.availableUpdatesTable > ul.tBody > li .SWUpdateSM,
	.availableUpdatesTable > ul.tBody > li .SWUpdateSMVD, 
	.updateStatusTable > ul.tBody > li .updateStatusSM {
		display: block; 
		font-size:12px;
	}
	.availableUpdatesTable > ul > li {  
		font-size:14px; 
	} 
	.availableUpdatesTable > ul.tBody li,	
	.availableUpdatesTable > ul li {
		padding: 5px 10px; 
	} 
	.availableUpdatesTable > ul > li:nth-child(2) { 
		width:auto;
		padding-left:0px; 
	}
	.availableUpdatesTable > ul > li:nth-child(2) > b { 
		display: inline-block;
	}
	.availableUpdatesTable > ul > li:nth-child(2) > br,
	.availableUpdatesTable > ul > li:nth-child(2) > .SWUpdateSM > br { 
		display: none;
	}   
	.notificationTypeForm,
	.myInformationForm{
		padding: 0px 10px;
	}
	.NotifnMgmtTable > ul > li:nth-child(2), 
	.NotifnMgmtTable > ul > li:nth-child(4) {
		width: 60px; 
	}
	.assignedUnitGroupsTableDiv{
		padding: 10px 10px;
	}
	.assignedUnitGrpsTableOuter,
	.myInformationTable > ul,
	.myInformationTable {
		width: 100%;
		display: block; 
	}
	.myInformationTable > ul.tHead > li:nth-child(3),
	.myInformationTable > ul.tBody > li:nth-child(1){
		display:none;
	}
	.myInformationTable > ul.tHead > li:nth-child(1) {
		width:74px; 
		padding:0px;		
	}
	.myInformationTable > ul.tHead > li:nth-child(2) {
		width:auto;  
	}
	.myInformationTable > ul.tBody > li:nth-child(2),
	.myInformationTable > ul.tBody > li:nth-child(3) {
		width:100%;  
		float:left;
		padding:7px 0px;
	}   
	.myInformationTable > ul > li .addUserForm > ul{
		padding: 0px;
	} 
}
@media only screen and (min-width: 768px) and (max-width: 834px) { 
/*** **************  ************** ****
	*** Dashboard ***
**** **************  ************** ***/      
	.mainMenuGrid > ul > li{
		width:33.33%;
	} 
	
}
@media only screen and (min-width: 835px) and (max-width: 1023px) {    
	.navbarLeft > li .navStoreIcon > img{
		width:22px;
		height:22px;
	} 
	.mainMenuGrid > ul > li{
		width:25%;
	}  
	.SWlistUnitsTable > ul.tBody > li .unitsListSM,
	.unitRegListTable > ul.tBody > li .unitsListSM{
		display:block;
		font-size:12px;
	}  
	.unitRegListTable > ul > li:nth-child(2) {
		padding-left: 10px;
	} 
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) {    
	.mainMenuGrid > ul > li{
		width:20%;
	}   
	.updateStatusTable > ul > li:nth-child(3),
	.updateStatusTable > ul > li:nth-child(4){
		display:none;
	} 
	.updateStatusTable > ul.tBody > li .updateStatusSM {
		display: block; 
	} 
}
@media only screen and (min-width: 1024px) and (max-width: 1380px) { 
} 
@media only screen and (min-width: 1380px) and (max-width: 1600px) { 
} 
@media only screen and (min-width: 1600px) and (max-width: 1920px) { 
	 
} 
@media only screen and (min-width: 1920px) {}   
@media only screen and (min-height: 310px) and (max-height: 520px) {  
	.welcomText.reLoginText { 
		display:none;
	} 
	.existingLoginForm > ul > li.marginBottom10px {
		margin-bottom: 0px;
	} 
	.welcomText { 
		margin: 75px 0px 0px; 
		font-size: 22px;
	} 
	.loginButton > ul > li .loginIWrapper {
		width: 370px; 
	}
	.modal-dialog.modal-md {
		width: 370px;
		margin:5px auto;
	} 
	.loginButton > ul > li .existingSingINBtn,
	.loginButton > ul > li .singINBtn { 
		padding: 12px 10px; 
		font-size: 18px;
	} 
	.navbar .welbiltLogo {
		margin: 2px 15px 2px;
	}  
}
@media only screen and (min-height: 380px) and (max-height: 520px) { 
	body.loginBg {
		background: #333333 url(../images/Keyvisual.png) no-repeat center center;
		background-size: 65% auto;
	}  
}
@media only screen and (min-height: 330px) and (max-height: 379px) { 
	body.loginBg {
		background: #333333 url(../images/Keyvisual.png) no-repeat center center;
		background-size: auto 300px;
	}  
}
@media only screen and (min-height: 310px) and (max-height: 329px) { 
	body.loginBg {
		background: #333333 url(../images/Keyvisual.png) no-repeat center center;
		background-size: auto 190px;
	}  
}
@media only screen and (min-width: 992px) {
	.inventoryListForm .tableFilterHeader > ul > li:nth-child(2) {
		min-width: 180px;
	}
}


/*** Tree ***/
@media only screen and (min-width: 1000px) and (max-width: 1119px) {  
	.orgznSiteTree > ul li > div > a { 
		width: 140px; 
		font-size: 11px;
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:140px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 180px; 
	}
	.collapseExpandHeader > ul > li {
		width: 180px; 
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 152px; 
	}
	.orgznSiteTree > ul li > div > a .treeText.deviceModel > b { 
		font-size: 10px; 
	}
} 
@media only screen and (max-width: 1119px) and (min-width: 1000px) {  
	.orgznSiteTree {
		padding: 64px 25px 25px 15px;
	} 
	.orgznSiteTree > ul li > div > a { 
		width: 130px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:130px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 170px; 
	}
	.collapseExpandHeader > ul > li {
		width: 165px;
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 142px;
	} 
	.orgznSiteTree ul.collapse .treeCountDispaly{
		width:130px;
	}

	.orgznSiteTree > ul li > div:before,
	.orgznSiteTree > ul li > div:after{
		left: -20px;
	}
} 
@media only screen and (min-width: 1120px) and (max-width: 1219px) {   
	.orgznSiteTree > ul li > div > a { 
		width: 165px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:165px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 205px; 
	}
	.collapseExpandHeader > ul > li {
		width: 205px; 
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 177px; 
	}
} 
@media only screen and (min-width: 1220px) and (max-width: 1290px) {   
	.orgznSiteTree > ul li > div > a { 
		width: 165px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:165px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 205px; 
	}
	.collapseExpandHeader > ul > li {
		width: 205px; 
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 177px; 
	}
}  
@media only screen and (min-width: 1366px) and (max-width: 1580px) {  
	.orgznSiteTree {
		padding: 64px 25px 25px 15px;
	} 
	.orgznSiteTree > ul li > div > a { 
		width: 180px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:180px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 210px; 
	}
	.collapseExpandHeader > ul > li {
		width: 205px;
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 186px; 
	} 
	.orgznSiteTree ul.collapse .treeCountDispaly{
		width:180px;
	}

	.orgznSiteTree > ul li .expdCollpse.collapsed:before{
		right:-5px;
	}

	.orgznSiteTree > ul li > div:before,
	.orgznSiteTree > ul li > div:after{
		left: -16px;
	}
} 
@media only screen and (min-width: 1581px) and (max-width: 1780px) {   
	.orgznSiteTree > ul li > div > a { 
		width: 235px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:235px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 275px; 
	}
	.collapseExpandHeader > ul > li {
		width: 275px; 
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 247px; 
	} 
} 
@media only screen and (min-width: 1781px) and (max-width: 2130px) {   
	.orgznSiteTree > ul li > div > a { 
		width: 255px; 
	} 
	.orgznSiteTree .collapsing,
	.orgznSiteTree .collapse:not(.show) { 
		width:255px; 
	}
	.orgznSiteTree > ul li > div > ul {
		margin-left: 295px; 
	}
	.collapseExpandHeader > ul > li {
		width: 295px; 
	}
	.orgznSiteTree > ul li .expdCollpse { 
		left: 267px; 
	} 
} 
@media only screen and (min-width: 320px) and (max-width: 420px) {
	
	
	/*** **************  ************** ****
		*** ORGANIZATION MANAGMENT ****
	**** **************  ************** ****/

	.selectedNavbarLeft > li .orgznManagementNav{
		width: 100%;
		float: left;
		padding: 1px 5px 1px 45px !important;
		font-size: 14px !important;
	}

	.orgznSiteTree {
		margin-top: 30px;
	  }

     .orgznManagementWrapper .orgznMgmtHeader > ul > li:nth-child(1),
	 .orgznManagementWrapper .orgznMgmtHeader > ul > li:nth-child(2)
	{
	 display: none;
	}

	.orgznManagementWrapper .collapseExpandHeader > ul{
	  display: block;
	  column-count: 3;
	}

	.orgznManagementWrapper .collapseExpandHeader > ul > li{
      width: auto;
	}

		
		.orgznSiteTree > ul li > div:before{
			top:22px;
		}
		
		.orgznSiteTre .orgznSiteTreeLiDiv >div:before{
			position: absolute;
			top: 22px;
			right: -20px;
			background: #4a5159;
			height: 2px;
			content: " ";
			width: 20px;
			z-index: 1;
		}
  
		
		.orgznSiteTree > ul li > div > a {
			width: 150px;
		}
	
		.orgznSiteTree ul.collapse .treeCountDispaly{
			width: 150px;
		}
		
		.orgznSiteTree > ul li > div > ul {
			margin-left: 35px;
			margin-top: 21px;
			display: inline-block;
		}
		
		.orgznSiteTree > ul li > div > a.treeNodeAvailable:before{
			display: none;
		}
		
		.orgznSiteTree > ul li .expdCollpse {
			top: 85px;
			left: 7px;
		}
		
		.orgznSiteTree > ul li > div > .treeNodeAvailable:after{
			left: 13px;
			position: absolute;
			top: 48px;
			right: -17px;
			background: #4a5159;
			height: 50px;
			content: " ";
			width: 2px;
			z-index: 1;
		}		
		.orgznSiteTree > ul li .treePrentDivActive > ul:after,
		.orgznSiteTree .treePrentDivActive > ul li > div > ul:after,
		.orgznSiteTreeLiDiv .treePrentDivActive > ul:after,
		.orgznSiteTree > ul li > .treePrentDivActive div > .treeNodeAvailable:after,
		.orgznSiteTree > ul li > .treePrentDivActive .treeActive:after {  
			background:#4f4f4f; 
		}
		
}
@media only screen and (min-width: 420px) and (max-width:1023px){

	.orgznSiteTree {
		margin-top: 0px;
	  }
	  .orgznManagementWrapper .collapseExpandHeader > ul{
		display: block;
		column-count: 6;
	  }
	 
		.orgznSiteTree > ul li > div:before{
			top:22px;
		}
	
		.orgznSiteTree > ul li > div > ul {
			margin-left: 35px;
			margin-top: 21px;
			display: inline-block;
		}
		
		.orgznSiteTree > ul li > div > a.treeNodeAvailable:before{
			display: none;
		}
	
		.orgznSiteTree > ul li .expdCollpse {
			top: 85px;
			left: 7px;
		}
		
		.orgznSiteTree > ul li > div > .treeNodeAvailable:after{
			left: 13px;
			position: absolute;
			top: 48px;
			right: -17px;
			background: #4a5159;
			height: 50px;
			content: " ";
			width: 2px;
			z-index: 1;
		}

		.orgznSiteTree > ul li .treePrentDivActive > ul:after,
		.orgznSiteTree .treePrentDivActive > ul li > div > ul:after,
		.orgznSiteTreeLiDiv .treePrentDivActive > ul:after,
		.orgznSiteTree > ul li > .treePrentDivActive div > .treeNodeAvailable:after,
		.orgznSiteTree > ul li > .treePrentDivActive .treeActive:after {  
			background:#4f4f4f; 
		}
}
@media only screen and (min-width:420px) and (max-width:560px) {
		/*** **************  ************** ****
			*** ORGANIZATION MANAGMENT ****
		**** **************  ************** ****/
		.selectedNavbarLeft > li .orgznManagementNav{
			width: 100%;
			float: left;
			padding: 1px 5px 1px 45px !important;
		    font-size: 14px !important;
		}

		.orgznSiteTree {
			margin-top: 30px;
			padding: 64px 25px 25px 20px;
		  }
	
		 .orgznManagementWrapper .orgznMgmtHeader > ul > li:nth-child(1),
		 .orgznManagementWrapper .orgznMgmtHeader > ul > li:nth-child(2)
		{
		 display: none;
		}
		.orgznManagementWrapper .collapseExpandHeader > ul{
		  display: block;
		  column-count: 3;
		}
	
		.orgznManagementWrapper .collapseExpandHeader > ul > li{
		  width: auto;
		}
}

@media only screen and (min-width:320px) and (max-width:767px){
	.orgznSiteTree{
	  padding: 64px 25px 25px 20px;
	  margin-top: 30px;
	}
	.orgznManagementWrapper.withFixedHeader div.collapseExpandHeader,
	.orgznManagementWrapper .collapseExpandHeader > ul{
		display: none;
	}

	.unitRegistrationWrapper .orgznManagementWrapper .orgznSiteTreeOuter .orgznSiteTree {
		padding: 20px;
		margin-top: 0px;
	}
}